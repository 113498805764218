<template>
  <div>
    <!-- banner -->
    <div class="banner">
      <el-carousel trigger="click" :height="80+'vh'" :interval='5000' loop>
        <el-carousel-item v-for="item in 3" :key="item">
          <img :ref="'imgs'+item" src="../../../assets/masterStation/banner.jpg" alt="柒彩科技" >
        </el-carousel-item>
      </el-carousel>
      <div class="banner_txt">
        <h3>连接科技与教育,让教学更成功</h3>
        <h4>OICAI &emsp;SMART &emsp; CAMPUS</h4>
        <div>
          <span style="background-color:#1F5BB3">产品服务</span>
          <span  @click="$router.push('/Joinus')">联系我们</span>
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="box introduce">
      <img src="../../../assets/masterStation/introduce.jpg" alt="柒彩科技">
      <div class='introduce_txt'>
        <titleTxt title='公司简介' describe="/WHO WE ARE" type="left"></titleTxt>
        <div class="txt_p">
          <p>
            贵州忠义染彩科技开发有限公司(GuiZhou 7colorSoftNew Co..Ltd,简称染彩科技)是一家以软件开发、互联网方案提供为基础,集系统集成、服务咨询、
            教育培训及硬件销售为一体的高科技企业!
          </p>
          <p>
            公司多年来对行业应用的了解,以及长期丰富经验的积累,集结具有多年开发大中型软件、经验丰富的IT行业软件开发团队。采用当今主流的，大型通信软件开发技术,
            打造出系列行业应用软件。包括:染彩云教育软件、医院综合信息管理系统(HIS、LIS.PACS)企业人事管理系统、智能办公系统(OA)策划及推广服务等!
          </p>
          <button>MORE > </button>
        </div>
      </div>
    </div>
    <!-- 产品中心 -->
    <div class="Product">
      <div class="box">
        <titleTxt title='产品中心' describe="WHAT DO WE HAVE" type="content"></titleTxt>
        <ul class="Product_list">
          <li v-for="(item,index) in ProductList" :key="index" @click="$router.push('/ProductCenter')">
            <div class="item_box">
              <h4>{{item.title}}</h4>
              <p>{{item.desribe}}</p>
              <img src="../../../assets/masterStation/item_bg2.png" alt="">
            </div>
            <div class="item_btn">
              <i class="iconfont icon-menu"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 案例展示 -->
    <div class="box case">
      <titleTxt title='案例展示' describe="/ WHAT CAN WE DO" type="left"></titleTxt>
      <div class="case_banner">
        <el-carousel trigger="click" height="435px" :interval='8000' indicator-position='none' ref='caseBanner' arrow="always" loop @change='caseChange'>
          <el-carousel-item v-for="(item,index) in produList" :key="index">
            <div class="case_item">
              <img :src="item.img" alt="柒彩科技">
              <div class="case_box">
                <span> {{ setitem(index + 1) }} </span>
                <div class="case_txt">
                  <div>{{item.title}}</div>
                  <p>{{item.p}} </p>
                  <button>DETALL > </button>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="carousel_item">
          <div>
            <i class="iconfont icon-zuo " @click="prev()"></i>
            <span v-for="(item,index) in 3" :key="item" :style=" caseIndex == index?'color:#F66006':'' " @click="setActiveItem(index)">{{item}}</span>
            <i class="iconfont icon-zuo " style="transform: rotateY(180deg);" @click="next()"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <!-- <div class="box Solution">
      <div class="title"> <span>染彩科技</span> 累计为超过<span style="font-size:34px">999+</span>家机构提供优质解决方案服务 </div>
      <el-carousel trigger="click" height="200px" :interval='5000' arrow="always" loop >
        <el-carousel-item v-for="item in 3" :key="item">
          <ul class="Solution_item">
            <li v-for="item in 6" :key="item">
              <i class="iconfont icon-boshimao"></i>
              <p> 遵义市教育科学研究院 </p>
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>

    </div> -->

  </div>
</template>

<script>
import titleTxt from '../component/title.vue'
export default {
  name: 'index',
  components: { titleTxt },
  data () {
    return {

      ProductList: [
        {
          title: 'EDU教育系统',
          desribe:
            'EDU教育系统是一项管理学校、学生、教师信息的系统，更是以考试管理作为主体业务，通过系统发起考试，主要实现了答题卡模板的智能化制作，选择题部分由计算机对考生填涂的信息点比对标准答案自动给分，'
        },
        {
          title: '成绩分析系统',
          desribe:
            '成绩分析系统是一套适合于高中、初中、小学以及教务处对每次考试按年段进行质量分析的软件，该软件操作简便，'
        },
        {
          title: '教育大数据云平台',
          desribe:
            '教育大数据云平台是一个综合管理系统，该平台包含了教育教学大数据监管系统、教育工程管理系统、教师人事管理与服务系统、教育督导系统。'
        },
        {
          title: '题库系统',
          desribe:
            '题库系统通过标注题型、答案、解析、知识点、难度、所属试卷、被组卷次数等信息，基于国家教育考试大纲，根据学科特性建设的层级清晰、完备的资源体系'
        },
        {
          title: '财税云系统',
          desribe:
            '财税云系统围绕遵义市财政工作数据化、信息化、智能化、一体化、现代化的目标，建设遵义市财政数据中心，'
        }
      ],
      produList: [
        {
          title: '教育云平台',
          img: require('@/assets/masterStation/图片3.png'),
          p: ' EDU教育云平台是一个教育管理系统，系统以教育和学习为出发点，解决目前教育信息化系统中存在的网络管理薄弱等问题,不仅满足当前教学教务管理的迫切应用需求， 也可适应未来不断发展的智慧校园建设新需求!建立一个覆盖范围广、实用性强的教育管理平台，为教育精细化管理提供有效的评测依据!EDU教育云平台是一个教育管理系统，系统以教育和学习为出发点，解决目前教育信息化系统中存在的网络管理薄弱等问题,不仅满足当前教学教务管理的迫切应用需求，也可适应未来不断发展的智慧校园建设新需求!建立一个覆盖范围广、实用性强的教育管理平台，为教育精细化管理提供有效的评测依据!'
        },
        {
          title: '成绩分析系统',
          img: require('@/assets/masterStation/图片2.png'),
          p: '成绩分析系统是一套适合于高中、初中、小学以及教务处对每次考试按年段进行质量分析的软件，该软件操作简便，是依托于学校的考试及诊断分析建立的一款面向家长和学生的个性化学习平台。 通过收集学生的考试成绩、试题等信息建立学生成长档案，自动归纳和整理学生考试和作业中的错题，利用专业分析精确定位知识漏洞，辅助教师为学生定制个性化提分方案，有效提升学生成绩。'
        },
        {
          title: '题库系统',
          img: require('@/assets/masterStation/图片4.png'),
          p: '题库系统通过标注题型、答案、解析、知识点、难度、所属试卷、被组卷次数等信息，基于国家教育考试大纲，根据学科特性建设的层级清晰、完备的资源体系。 题库系统包含试题库和组卷，试题库是按照不同的学科门类及其内在联系，将不同的试题分门别类地汇集起来，为特定学科知识和技能测试提供备选试题的一种系统资源。 组卷是按照不同的组卷方式将试题组合成测试卷，系统可以实现根据教师端用户选择的组卷维度，运用大数据精准计算引擎推送相应试题，帮助教师高效组卷。 本系统汇集了中小学各年级段各种试卷、真题、练习题库，涵盖了各个学科试题、试卷，方便各教师、专家参考'
        }
      ],

      caseIndex: 0 // 案例轮播当前index
    }
  },
  mounted () { },
  methods: {

    // 案例数字小于10 的前加0
    setitem (i) {
      return i < 10 ? '0' + i : i
    },
    // 案例切换事件
    caseChange (e) {
      this.caseIndex = e
    },
    // 案例轮播上 一张
    prev () {
      this.$refs.caseBanner.prev()
    },
    // 案例轮播下 一张
    next () {
      this.$refs.caseBanner.next()
    },
    // 手动切换
    setActiveItem (item) {
      this.$refs.caseBanner.setActiveItem(item)
    }
  }
}
</script>
<style lang="less" scoped>
.banner {
  position: relative;
  img {
    width: 100%;
  }
  .banner_txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    z-index: 10;
    h3 {
      margin-top: 0;
      font-size: 32px;
      margin-bottom: 20px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
    div {
      margin-top: 50px;
    }
    span {
      display: inline-block;
      width: 120px;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
      font-size: 16px;
      margin: 0 15px;
      background-color: #fd6702;
      cursor: pointer;
    }
  }
  /deep/ .is-active{
    button{
      background-color: #365685 ;
    }
  }
}
.introduce {
  padding: 100px 0;
  display: flex;
  justify-content: space-between;
  img {
    width: 48%;
  }
  .introduce_txt {
    width: 48%;
    .txt_p {
      margin-top: 25px;
      color: #6c6c6c;
      line-height: 28px;
      p {
        margin-bottom: 15px;
      }
      button {
        background-color: #fff;
        border: 1px solid #efb89a;
        padding: 5px 10px;
        color: #efb89a;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}
.Product {
  padding: 50px 0 75px;
  background: url("../../../assets/masterStation/bg1.jpg");
  .Product_list {
    margin-top: 55px;
    display: flex;
    justify-content: space-around;
    li {
      margin-right: 30px;
      &:nth-child(6) {
        margin-right: 0;
      }
      .item_box {
        width: 174px;
        height: 174px;
        border-radius: 5px;
        padding: 20px 15px;
        overflow: hidden;
        background-color: #fff;
        position: relative;
        background: url("../../../assets/masterStation/item_bg.png") no-repeat;
        background-position: -10px -10px;
        background-size: 110% 107%;
        h4 {
          text-align: center;
          font-size: 16px;
        }
        p {
          color: #949494;
          line-height: 28px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          font-size: 14px;
        }
        img {
          position: absolute;
          top: -10px;
          left: -10px;
          width: 110%;
          height: 107%;
          opacity: 0;
          transition: all 1s linear;
        }
      }
      .item_btn {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        background-color: #fff;
        margin-top: 15px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 5px 0 #ccc;
        transition: all 1s linear;
        i {
          display: inline-block;
          font-size: 30px;
          background-image: linear-gradient(45deg, #607cab, #84d0d4);
          background-clip: text;
          color: transparent;
          margin-left: 10px;
          margin-top: 15px;
        }
      }
      &:hover {
        img {
          opacity: 0.9 !important;
        }
        .item_btn {
          background-color: #fb6905;
          i {
            color: #fff;
          }
        }
      }
    }
  }
}
.case {
  padding: 90px 0 50px;
  .case_banner {
    /deep/ .el-carousel__arrow {
      display: none !important;
    }
    .case_item {
      display: flex;
      img {
        height: 100%;
        margin-top: 55px;
        height: 380px;
        display: block;
      }
      .case_box {
        width: 50%;
        position: relative;
        height: 380px;
        span {
          font-size: 300px;
          color: #f4f5fa;
          text-align: right;
          margin-top: -55px;
          display: block;
        }
        .case_txt {
          width: 500px;
          // height: 300px;
          position: absolute;
          bottom: 0;
          left: -65px;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 10px 45px 0px rgba(199, 199, 199, 0.6);
          box-sizing: border-box;
          padding: 30px;
          div {
            font-size: 20px;
            height: 60px;
            margin-bottom: 10px;
            position: relative;
            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0px;
              width: 40px;
              height: 1px;
              background-color: #171718;
            }
          }
          p {
            color: #5b5b5b;
            line-height: 28px;
            max-height: 165px;
             display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;
          }
          button {
            background-color: #f66006;
            padding: 8px 15px;
            color: #ffe1ab;
            font-weight: 600;
            cursor: pointer;
            border: none;
            border-radius: 5px;
            margin-top: 10px;
          }
        }
      }
    }
    .carousel_item {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      margin-top: 50px;
      div {
        display: flex;
        align-items: center;
        color: #e7e7e7;
      }
      i {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #e7e7e7;
        font-size: 20px;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          background-color: #f66006;
          border-color: #f66006;
          color: #fff;
        }
      }
      span {
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}

.Solution {
  padding: 50px 0 100px;
  .title {
    font-size: 24px;
    padding-bottom: 30px;
    text-align: center;
    border-bottom: 2px solid #e7e7e7;
    margin-bottom: 35px;
    span {
      color: #1993d7;
    }
  }
  .el-carousel {
    width: 80%;
    margin: 0 auto;
  }
  .Solution_item {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 295px;
      margin-right: 75px;
      height: 60px;
      border: 1px solid #f2f2f2;
      background-color: #fafafa;
      color: #6c6c6c;
      box-sizing: border-box;
      padding: 5px 30px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      i {
        font-size: 60px;
        color: #1993d7;
        margin-right: 10px;
      }
      &:nth-child(3n){
        margin-right: 0;
      }
    }
  }
  /deep/.is-active {
    button {
      background-color: #f66006;
    }
  }
  /deep/.el-carousel__button {
    background-color: #cacaca;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
</style>
